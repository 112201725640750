export const AboutFocus = [
    {
        "id": "001",
        "title": "Consolation",
        "description": "Lorem Ipsum is simply is very dummy text of the printings and type and setting for content",
        "img": "https://i.ibb.co/GQmKHnh/heart.png"
    },
    {
        "id": "002",
        "title": "Familiarity",
        "description": "Get our text demo is simply dummy text of the printings and type and setting for content",
        "img": "https://i.ibb.co/N2M8n97/love.png"
    },
    {
        "id": "003",
        "title": "Outcome",
        "description": "Lorem Ipsum is simply is very dummy text of the printings and type setting for content",
        "img": "https://i.ibb.co/t2cczRh/outcome.png"
    }
]