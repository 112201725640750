import './App.css';
import Body from './ComponentsKelan/Body';
import Header from './ComponentsKelan/Header';
import Footer from './ComponentsKelan/Footer';
import { useEffect, useState } from 'react';
import Renew from './ComponentsRenew/Renew';

function App() {

  const [appoint, setAppoint] = useState(false)
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isBackTopBtnActive, setIsBackTopBtnActive] = useState(false);
  const currentDomain = window.location.hostname;

  useEffect(() => {
    setTimeout(() => {
      setAppoint(true)
    }, 5000)
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setIsHeaderActive(true);
        setIsBackTopBtnActive(true);
      } else {
        setIsHeaderActive(false);
        setIsBackTopBtnActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  if (currentDomain === 'www.kadali.co.in' || currentDomain === 'kadali.co.in') {
    return (
      <div className="App">
        <Header isHeaderActive={isHeaderActive} />
        <Body />
        <Footer />
      </div>
    );
  } else if (currentDomain === 'www.renewcosmeticclinics.com') {
    return <Renew />
  } else if (currentDomain === 'localhost') {
    return (
      <div>
        <h1>LocalHost</h1>
      </div>
    )
  }
}

export default App;
