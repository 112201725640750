export const FakeBlog = [
    {
        "id": "001",
        "title": "Maximize Your 2019 Dental Insurance with lots of Benefits",
        "span1": "Post by: Salman",
        "span2": "12 Oct, 2019",
        "img": "https://i.ibb.co/1LnXPFk/blog1.jpg"
    },
    {
        "id": "002",
        "title": "Are Your Teeth Making You Old? Are You Surprised! Hope you'r Shocked",
        "span1": "Post by: admin",
        "span2": "14 Oct, 2019",
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"
    },
    {
        "id": "003",
        "title": "Dental Implants: The Next Best Thing to Get Natural Teeth",
        "span1": "Post by: Salman",
        "span2": "10 Jan, 2020",
        "img": "https://donto-react.netlify.app/static/media/blog3.4104f17c.jpg"
    }
]