import React from 'react';
import Appoinment from '../../../Pages/Home/Appoinment/Appoinment';
import Banner from '../Bannner/Banner';
import Details from '../Details/Details';

const Dentist = () => {
    return (
        <>
            <Banner />
            <Details />
            {/* <Appoinment /> */}
        </>
    );
};

export default Dentist;